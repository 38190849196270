export enum ERROR_CODES {
  COMPANYDATA_NOT_FOUND = 0,
  ORDER_NOT_FOUND = 1003,
  PI_NUMBER_NOT_FOUND = 1005,
  OMS_KEY_ENTRY_NOT_FOUND = 1006
}

export enum AuthErrorCodes {
  NO_COMPANY_DATA = 999,
  DOMAIN_USAGE = 998,
  USER_NOT_FOUND = 997,
  NO_APP_PERMISSION = 0
}
