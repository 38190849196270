import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FormDataRequestService, L1L3AuthService } from 'ngx-auth-util-lib';
import { LoadingService } from './loading/loading.service';
import { CompanyDataMgmtService } from './api/companydatamgmt.service';
import { ApplicationIds } from '../models/application';
import { environment } from '../../environments/local/environment';
import { UtilService } from './util.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './error-handling/notification.service';

@Injectable({
  providedIn: 'root'
})
export class PlanningToolService {
  constructor(
    private companyDataMgmtService: CompanyDataMgmtService,
    private loadingService: LoadingService,
    private authService: L1L3AuthService,
    private utilService: UtilService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private formDataRequestService: FormDataRequestService
  ) {}

  openTool(applicationId: ApplicationIds) {
    this.loadingService?.setLoading(true);
    this.companyDataMgmtService
      .getCompanyData()
      .pipe(finalize(() => this.loadingService.setLoading(false)))
      .subscribe({
        next: value => {
          let userInfo = this.authService.getUserInfo();
          let path = this.getPath(applicationId, value.showAdditionalDocuments);
          if (value?.id && userInfo && path.length > 0) {
            try {
              this.formDataRequestService.sendRequest(
                path,
                {
                  lgkCompany: value.companyName,
                  lgkCustNum: value.companyCustomerNumber,
                  lgkPerson: userInfo.display_name,
                  lgkPersNum: '',
                  lgkPostCode: value.address?.postalcode,
                  lgkEmail: userInfo.email,
                  lgkPhone: userInfo.phone_number,
                  lgkCustGroup: 'Extern',
                  lgkLocation: value.address?.city
                },
                true,
                'POST'
              );
            } catch (e) {
              if (this.utilService.isNS_ERROR(e as Error)) {
                alert(this.translateService.instant('ERRORS.ALLOW_POP_UPS'));
              } else {
                this.notificationService.showError(this.translateService.instant('ERRORS.UNEXPECTED_ERROR'));
              }
            }
          }
        },
        error: err => {
          this.showErrorMessage(err);
        }
      });
  }

  private showErrorMessage(err: any): void {
    // TODO?
    throw err;
  }

  private getPath(applicationId: string, showAdditionalDocuments: boolean): string {
    if (applicationId === ApplicationIds.ELECTRICITY_CALCULATION_TOOL) {
      return environment.links.applications.elec_calc_tool;
    }
    if (applicationId === ApplicationIds.TENDER_TOOL) {
      if (showAdditionalDocuments) {
        return environment.links.applications.tender_tool_extend;
      } else {
        return environment.links.applications.tender_tool_light;
      }
    }
    // should not happen
    return '';
  }
}
