import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/local/environment';
import { NavLink } from 'ngx-heka-design-lib/lib/models/navlink';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(private sanitizer: DomSanitizer, private translateService: TranslateService, private router: Router) {}

  goToLink(link: string) {
    window.open(link, '_blank', 'noopener,noreferrer');
  }

  openAppUrlInNewTab(relativePath: string) {
    const link = environment.redirect.host + relativePath;
    this.goToLink(link);
  }

  isNS_ERROR(e: Error) {
    return e.name === 'NS_ERROR_FAILURE';
  }

  calculateCurrentNavLinkIndex(navLinks: NavLink[], urlSegments?: UrlSegment[]) {
    if (urlSegments?.length) {
      const slicedUrl = urlSegments[urlSegments.length - 1];
      let currentIndex = 0;
      if (slicedUrl && navLinks?.length > 0) {
        const currentLink = navLinks.find(link => link.link.indexOf(slicedUrl?.path) > -1);
        if (currentLink?.index) {
          currentIndex = currentLink.index;
        }
      }
      return currentIndex;
    }
    return 0;
  }

  public compareSelectables(a: any, b: any): boolean {
    if (a === b) {
      return true;
    } else {
      return false;
    }
  }
}
