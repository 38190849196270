import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { OauthComponent } from './components/oauth/oauth.component';
import { L1AuthGuard, L3AuthGuard } from 'ngx-auth-util-lib';
import { NoAppAccessComponent } from './components/no-app-permission/no-app-access.component';
import { NoAppAccessGuard } from './guards/no-app-access-guard.service';

const routes: Routes = [
  {
    path: 'oauth',
    component: OauthComponent
  },
  {
    path: 'no-app-access',
    component: NoAppAccessComponent,
    canActivate: [NoAppAccessGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [L1AuthGuard, L3AuthGuard]
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' }
];

const config: ExtraOptions = {
  useHash: false,
  scrollPositionRestoration: 'enabled',
  paramsInheritanceStrategy: 'always' //TODO keep eye on
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
