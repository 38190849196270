import { Component, OnInit } from '@angular/core';
import { AccountMenuItem, BasicAccountMenuItems } from 'ngx-heka-design-lib';
import { environment } from '../../../environments/local/environment';
import { L1L3AuthService } from 'ngx-auth-util-lib';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'ngx-oms-minimal-menu-bar',
  templateUrl: './oms-minimal-menu-bar.component.html',
  styleUrls: ['./oms-minimal-menu-bar.component.scss']
})
export class OmsMinimalMenuBarComponent implements OnInit {
  accountMenu: AccountMenuItem[] = [];
  env = environment;

  constructor(public authService: L1L3AuthService, private utilService: UtilService) {}

  ngOnInit(): void {
    this.setupAccountMenu();
  }

  private setupAccountMenu() {
    const newAccountMenu = BasicAccountMenuItems;
    newAccountMenu[0].clickHandler = () => this.authService.goToProfile();
    newAccountMenu[1].clickHandler = () => this.utilService.goToLink(environment.imprint.endpoint.paths.imprint);
    newAccountMenu[2].clickHandler = () => this.authService.logout();
    this.accountMenu = [newAccountMenu[0], newAccountMenu[1], newAccountMenu[2]];
  }
}
