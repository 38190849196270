import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OauthComponent } from './oauth/oauth.component';
import { GlobalLoadingComponent } from './loading/global-loading/global-loading.component';
import { NoAppAccessComponent } from './no-app-permission/no-app-access.component';
import { NgxHekaDesignLibModule } from 'ngx-heka-design-lib';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { OmsMinimalMenuBarComponent } from './oms-minimal-menu-bar/oms-minimal-menu-bar.component';

@NgModule({
  declarations: [OauthComponent, GlobalLoadingComponent, NoAppAccessComponent, OmsMinimalMenuBarComponent],
  imports: [CommonModule, NgxHekaDesignLibModule, TranslateModule, MatButton, MatProgressSpinner]
})
export class ComponentsModule {}
